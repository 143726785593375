<template>

  <Section 
  background="#e8e5de"
  :class="sectionTotal === sectionIndex + 1 ? 'bottom-reset' : ''"
  >
    <Container>

      <FadeIn class="mx-auto max-w-2xl">
        <h2 class="text-section-title">{{ content.title }}</h2>
        <div class="mt-10">
          <div v-for="(faq, index) in content.faqs" class="border-b border-[#f9f8f6]">
            <div @click="togglePanel(index)" class="pointer py-4 md:py-6">
              <div class="flex w-full items-start justify-between text-left">
                <div class="text-base font-semibold leading-6 w-[calc(100%-4rem)] lg:text-lg">{{ faq.faq_item_id.question }}</div>
                <div class="ml-6 bg-[#8293f8] border-2 border-[#5a6ded] rounded w-8 h-8 flex items-center justify-center">
                  <Icon name="ph:caret-down" v-if="activePanel !== index" class="h-6 w-6 text-[#fff]" />
                  <Icon name="ph:caret-up" v-else class="h-6 w-6 text-[#fff]" />
                </div>
              </div>
            </div>
            <div v-show="activePanel === index" class="prose">
              <div class="text-base faq__a" 
              v-interpolation 
              v-html="faq.faq_item_id.answer"
              ></div>
            </div>
          </div>
        </div>
      </FadeIn>

    </Container>
  </Section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  sectionTotal: {
    type: Number,
    default: 0
  },
  sectionIndex: {
    type: Number,
    default: 0
  }
})

const activePanel = ref(999)

const togglePanel = index => {
  const activeIndex = activePanel.value
  if (index === activeIndex) {
    activePanel.value = 999
  } else {
    activePanel.value = index
  }
}

</script>

<style scoped>
/* .bottom-reset {
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important;
  
  @media (min-width: 768px) {
    margin-bottom: -6rem!important;
  }

  @media (max-width: 767px) {
    margin-bottom: -4rem!important;
  }
} */
</style>